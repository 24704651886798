<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('MENU.invoice_details')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <router-link to="/pos/pos-session" class="btn btn-light font-weight-bolder">
                        <v-icon>mdi-chevron-left</v-icon>
                        {{ $t('back') }}
                    </router-link>
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <div class="mb-5 d-flex justify-content-end">

                    <b-button-group>
                        <b-button v-if="$can('pos_invoice.update')" :to="`/sales/sales_invoices/edit/${id}`">Edit</b-button>
                        <b-button :to="`/sales/payment_sales_invoices/create/${id}/invoice`">{{ $t('add_payment') }}</b-button>
                        <b-button @click="draftItem(id)">{{$t('mark_as_draft')}}</b-button>
                        <b-button variant="danger" v-if="$can('sales_invoices.delete')" @click="deleteInvoiceItem(id)">{{$t('delete')}}</b-button>
                    </b-button-group>
                </div>
                <b-tabs content-class="mt-3">
                    <div class="mt-5 d-flex justify-content-end">
                        <router-link v-if="$can('sales_invoice_print.create')" target="_blank" class="btn btn-dark font-weight-bolder mr-1 ml-1" :to="`/print/sales/${id}/print`">
                            {{ $t('print') }}
                        </router-link>
                        <router-link v-if="$can('sales_invoice_print.create')" target="_blank" class="btn btn-dark font-weight-bolder mr-1 ml-1" :to="`/print/sales/${id}`">
                            {{ $t('pdf') }}
                        </router-link>
                    </div>
                    <b-tab :title="$t('pos_session.invoices')" active>
                        <div class="mt-5">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>{{$t('pos_invoice.invoice_no')}}</th>
                                    <th>{{$t('pos_invoice.currency')}}</th>
                                    <th>{{$t('pos_invoice.invoice_total')}}</th>
                                    <th>{{$t('pos_invoice.refunded')}}</th>
                                    <th>{{$t('pos_invoice.paid_amount')}}</th>
                                    <th>{{$t('pos_invoice.unpaid_amount')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="invoice">
                                        <td>#{{ invoice.id }}</td>
                                        <td> {{ invoice.currency_name }}</td>
                                        <td> {{ invoice.invoice_total }}</td>
                                        <td> {{ invoice.total_refunds }}</td>
                                        <td> {{ invoice.payment_amount }}</td>
                                        <td> {{ (invoice.invoice_total - invoice.payment_amount).toFixed(2) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('activity_log.activity_log')">
                        <div class="mt-5">
                            <activity-log :inner-key="key" :id="id"></activity-log>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('payment')">
                        <div class="mt-5">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>{{$t('pos_invoice.payment_info')}}</th>
                                    <th>{{$t('pos_invoice.amount_and_status')}}</th>
                                    <th>{{$t('actions')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in data" :key="row.id">
                                        <td>
                                            <p class="m-0">#{{row.customer_id}} - {{$t('pos_invoice.pos_client')}} {{ row.customer_fullname }} </p>
                                            <p class="m-0">{{$t('pos_invoice.invoice_id')}} #{{ row.type_id }}</p>
                                            <p class="m-0">{{ row.collected_user_name }}</p>
                                            <p class="m-0">{{ row.payment_method_name }}</p>    
                                        </td>
                                        <td>
                                            <p>{{ row.amount }} {{ row.currency_code }}</p>
                                            <p>{{ row.payment_status_name }}</p>
                                        </td>
                                        <td>
                                            <!-- <template slot="actions" slot-scope="props"> -->
                                                <!-- <i class="fas fa-search"></i> -->
                                                <v-btn icon color="pink" title="Print" @click="printPayment('print', row.id)">
                                                    <v-icon small class="mr-2">mdi-printer</v-icon>
                                                </v-btn>
                                                <v-btn icon color="pink" title="View" @click="showDetails(row.id)">
                                                    <v-icon small class="mr-2">mdi-eye</v-icon>
                                                </v-btn>
                                                <v-btn icon title="Edit" v-if="$can('payment_sales_invoices.update')" color="pink" :to="`/sales/payment_sales_invoices/edit/${row.id}/invoice`">
                                                    <v-icon small class="mr-2">mdi-pencil</v-icon>
                                                </v-btn>
                                                 <v-icon small title="Delete" v-if="$can('payment_sales_invoices.delete')" @click="deleteItem(row)">mdi-delete</v-icon>
                                            <!-- </template> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h4 class="pt-5">{{$t('pos_invoice.payment_summary')}}</h4>
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>{{$t('pos_invoice.invoice_no')}}</th>
                                    <th>{{$t('pos_invoice.currency')}}</th>
                                    <th>{{$t('pos_invoice.invoice_total')}}</th>
                                    <th>{{$t('pos_invoice.refunded')}}</th>
                                    <th>{{$t('pos_invoice.paid_amount')}}</th>
                                    <th>{{$t('pos_invoice.unpaid_amount')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr v-for="row in invoices" :key="row.id"> -->
                                    <tr v-if="invoice">
                                        <td>#{{ invoice.id }}</td>
                                        <td> {{ invoice.currency_name }}</td>
                                        <td> {{ invoice.invoice_total }}</td>
                                        <td> {{ invoice.total_refunds }}</td>
                                        <td> {{ invoice.payment_amount }}</td>
                                        <td> {{ (invoice.invoice_total - invoice.payment_amount).toFixed(2) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('setup_installment_agreement')" v-if="$can('installments.create')" @click="toInstallment(id)">
                        <div class="mt-5">
                        </div>
                    </b-tab>
                    <b-tab :title="$t('create_credit_note')" v-if="$can('credits.create')" @click="toSalesCredit(id)">
                        <div class="mt-5">
                        </div>
                    </b-tab>
                    <b-tab :title="$t('sales_refund.sales_refund')" v-if="$can('sales_refund.create')" @click="toSalesRefund(id)">
                        <div class="mt-5">
                        </div>
                    </b-tab>
                    <b-tab :title="$t('assign_costs_centers')" v-if="$can('cost_centers.create')" @click="toCostCenter(id)">
                        <div class="mt-5">
                        </div>
                    </b-tab>
                </b-tabs>
            </div>

        </div>
        <!--end::customer-->
       
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-pos-session",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'sales/payment_sales_invoice/payment_details',
                mainRouteInvoice: 'sales/sales_invoices',
                mainRouteInvoiceChangeStatus: 'sales/sales_invoice/change-status',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'SalesInvoice',
                data: {},
                invoice : null,
                selectedIndex: 0,
                current_tab: this.$route.params.current_tab? this.$route.params.current_tab : null,
                status: 1,
            };
        },

        methods: {
            toInstallment(id) {
                window.open(`/installment/installment/create/${id}`, "_blank");
            },
            toSalesCredit(id) {
                window.open(`/sales/credits/create/${id}`, "_blank");
            },
            toSalesRefund(id) {
                window.open(`/sales/sales_refund/create/${id}`, "_blank");
            },
            toCostCenter(id) {
                window.open(`/sales/cost-center/create/${id}`, "_blank");
            },
            async getData() {
                await ApiService.get(`${this.mainRoute}/${this.id}/SalesInvoice`).then((response) => {
                    this.data = response.data.data;
                    // this.invoice = (response.data.data[this.selectedIndex] && response.data.data[this.selectedIndex].invoice) ? response.data.data[this.selectedIndex].invoice : null;
                });
            },
            async getInvoiceByInvoiceData() {
                await ApiService.get(`${this.mainRouteInvoice}/${this.id}`).then((response) => {
                    this.invoice = response.data.data;
                    // this.invoice = (response.data.data[this.selectedIndex] && response.data.data[this.selectedIndex].invoice) ? response.data.data[this.selectedIndex].invoice : null;
                });
            },
            printPayment(action, id) {
                if (id) {
                    window.open("/print/sales/payment/" + id + '/' + action, "_blank");
                }
            },
            printPosInvoice(action, id) {
                if (id){
                    window.open("/print/pos-sessions/pos-refund/"+id+'/'+action, "_blank");
                }
            },

            deleteInvoiceItem(id) {
                this.$confirmAlert('', this.actionInvoiceDelete, id);
            },

            actionInvoiceDelete(id) {
                ApiService.delete(`pos/pos-invoice/${id}`).then((response) => {
                    this.$successAlert(response.data.message);
                    this.$router.push({name: 'sales_invoices.index'});
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            actionDelete(item) {
                ApiService.delete(`sales/payment_sales_invoices/${item.id}`).then((response) => {
                    this.$successAlert(response.data.message);
                    this.getInvoiceByInvoiceData();
                    this.getData();
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            draftItem(id) {
                this.$confirmAlert(this.$t('do_you_want_make_it_as_draft'), this.actionDraft, id);
            },

            actionDraft(id) {
                ApiService.patch(this.mainRouteInvoiceChangeStatus + '/' + id, {
                    status: this.status,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.getInvoiceByInvoiceData();
                    this.getData();
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            showDetails(id){
                this.$router.push({name: 'payment_sales_invoices.details', params:{id: id}});
            }
            
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.invoice_details")}]);
            this.getInvoiceByInvoiceData();
            this.getData();
        },
    };
</script>